<template>
  <div v-loading="loading" class="org-tree">
    <div class="title">机构目录</div>
    <el-input placeholder="输入关键字进行过滤" v-model="filterText" clearable></el-input>

    <div class="tree-wrapper">
      <el-tree
        id="imageWrapper"
        draggable
        @node-drop="handleDrop"
        ref="orgTreeRef"
        node-key="id"
        default-expand-all
        highlight-current
        :data="orgTree"
        :props="defaultProps"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        @node-click="onNodeClick"
      >
        <span
          slot-scope="{ node, data }"
          class="custom-tree-node showname"
          :title="node.label"
          v-text="node.label"
        ></span>
      </el-tree>
    </div>
  </div>
</template>

<script>
import { getOrgTree, changeOrder, getOrgTreeAllData } from '@/api/common/index.js';
import html2canvas from 'html2canvas';

export default {
  data() {
    return {
      loading: false,
      filterText: '',
      orgTree: [],
      orgTreeCopy: [],
      treeAllData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
  },
  created() {
    this.getOrgTreeData();
    this.getOrgTreeAllData();
  },
  methods: {
    getOrgTreeData() {
      this.loading = true;
      getOrgTree()
        .then(res => {
          this.orgTreeCopy = res.data;
          this.orgTree = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getOrgTreeAllData() {
      this.loading = true;
      getOrgTreeAllData()
        .then(res => {
          this.treeAllData = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 节点被点击时回调
    onNodeClick(data) {
      this.$emit('node-click', data);
    },
    // 重置状态，取消高亮
    reset() {
      this.$refs.orgTreeRef.setCurrentKey(null);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      if (draggingNode.data.pid !== dropNode.data.pid || dropType === 'inner') {
        this.getOrgTreeData();
        return this.$message.error('只允许同级机构进行排序');
      } else {
        const params = {};
        params.id = draggingNode.data.id;
        params.pid = draggingNode.data.pid;
        params.originalSort = draggingNode.data.sort; // 变更前sort
        params.sort = dropNode.data.sort; // 变更后sort
        this.postChangeOrder(params);
        // console.log(params);
      }
    },
    postChangeOrder(params) {
      changeOrder(params)
        .then(res => {
          this.$message.success('排序成功！');
        })
        .catch(err => {
          this.getOrgTreeData();
        });
    },
    toImage() {
      this.loading = true;
      this.orgTree = this.treeAllData;
      setTimeout(res => {
        html2canvas(document.querySelector('#imageWrapper'), {
          useCORS: true
        }).then(canvas => {
          const imageUrl = canvas.toDataURL('image/png'); // 将canvas转成base64图片格式
          this.downLoadClick(imageUrl, '组织架构图');
        });
      }, 500);
    },
    downLoadClick(img, name) {
      const downloadElement = document.createElement('a');
      const href = img; // 创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.download = name; // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href);
      this.orgTree = this.orgTreeCopy;
      this.loading = false;
    }
  },
  watch: {
    filterText(val) {
      this.$refs.orgTreeRef.filter(val);
    }
  }
};
</script>

<style lang="less" scoped>
.org-tree {
  box-sizing: border-box;
  width: 260px;
  height: 90%;
  border: 1px solid #dcdfe7;
  margin-right: 16px;

  .title {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 12px;
    background: #f3f6fe;
    color: #303133;
  }

  .tree-wrapper {
    height: calc(100vh - 340px);
    padding: 16px 0;
    overflow: auto;

    .el-tree {
      box-sizing: border-box;
      padding: 0 16px;
      min-width: 100%;
      display: inline-block;
    }

    .showname {
      // width: 260px; // 定宽
      // overflow: hidden !important; // 溢出部分隐藏
      // white-space: nowrap !important; //禁止自动换行
      // text-overflow: ellipsis !important; // 使溢出部分以省略号显示
      // display: block !important;
    }
  }
}
</style>
